<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Scientific Production Report
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <!-- <a href="researcher-scientific-production.php" class="btn btn-icon btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Back"><i data-feather="chevron-left"></i></a> -->
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="row d-flex align-items-end">
              <div class="col-md-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Begin year</label>
                  <date-picker
                    v-model="filters['from']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="checkFilters"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >End year</label>
                  <date-picker
                    v-model="filters['to']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="checkFilters"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >or Year promotion</label>
                  <date-picker
                    v-model="filters['calculateBetween']"
                    :disabled="disabledInput"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="checkFilters('fromPromotion')"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-1">
                  <button
                    class="btn btn-dark w-100"
                    @click="applyFilters"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div class="col-md-1">
                <div class="mb-1">
                  <button
                    class="btn btn-secondary w-100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Reset filter"
                    @click="resetFilter"
                  >
                    <i data-feather="x" />
                  </button>
                </div>
              </div>
            </div>

            <Stats
              v-if="!loading"
              :year-between="yearBetween"
              :current-year="currentYear"
            />

            <!-- table -->

            <ScientificProductionTable
              v-if="!loading"
              :year-between="yearBetween"
            />

            <!-- end table -->

          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Stats from '../../researcher/scientific-production-report/components/Stats.vue'
import ScientificProductionTable from './components/ScientificProductionTable.vue'

export default {
  components: {
    DatePicker,
    Stats,
    ScientificProductionTable,
  },
  data() {
    return {
      loading: true,
      yearBetween: {},
      filters: [],
      filterInfoSheet: 1,
      currentYear: 0,
      disabledInput: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.currentYear = new Date().getFullYear()
    this.loading = false
  },
  methods: {
    applyFilters() {
      this.yearBetween = { ...this.filters }
      this.changeCurrentYearText(this.filters)
    },
    openFilters() {
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    changeCurrentYearText(filter) {
      if (filter.from && filter.to) {
        this.currentYear = `${filter.from} - ${filter.to}`
      } else if (filter.from) {
        this.currentYear = `From ${filter.from}`
      } else if (filter.to) {
        this.currentYear = `To ${filter.to}`
      } else {
        this.currentYear = new Date().getFullYear()
      }
    },
    // async goToIndex(route) {
    //   this.$router.push({ name: route, query: { user_id: this.userIdSelected } })
    // },
    resetFilter() {
      this.filters = []
    },
    checkFilters(type = '') {
      if (this.filters.from && this.filters.to && type === 'fromPromotion') {
        this.filters.from = null
        this.filters.to = null
      } else if ((this.filters.from || this.filters.to) && this.filters.calculateBetween) {
        this.disabledInput = true
        this.filters.calculateBetween = null
      } else if (!this.filters.from && !this.filters.to) {
        this.disabledInput = false
      } else {
        this.disabledInput = true
      }
    },
  },
}
</script>
